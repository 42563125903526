@import "./variable";
@import "./mixing";
@import "./gird";
.login-bg {
  width: 100%;
  height: 100vh;
  position: relative;
  @include background;
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(to right, #53525273 0%, #34343438 100%);
  }
  .login-content {
    align-items: center;
    .login-rimi {
      border-radius: 10px;
      padding: 30px;
      min-width: 500px;
      position: relative;
      border: 1px solid $white;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.05);
      background: $white;
      @include  tablet{
        min-width: 100%;
      }
      form {
        padding-top: 7px;
            label{
                margin-bottom: 10px;
                margin-top: 7px;
                font-weight: 500;
                color: $primary;
                font-size: 14px;
                text-transform: capitalize;
            }
        input {
          width: 100%;
          padding: 10px;
          border-radius: 5px;
          margin-bottom: 10px;
          height: 43px;
          font-size: 13px;
          text-transform: capitalize;
          border: solid 2px $secendery;
        }
        button{
            font-size: 15px;
        }
      }
      .login-header {
        .header-icon {
          padding: 10px 0 20px;
          img {
            width: 50px;
          }
        }
     
.header-text{
    h3{
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 3px;
        span{
            color: $secendery;
            margin-left: 3px;
        }
    }
}
       
      
     
       
      }

      a.forgot-pw {
        font-weight: 500;
        font-size: 13px;
        margin-top: 10px;
        display: block;
      }

      .have-anac{
        margin-top: 16px;
        font-size: 14px;
        color: $black;
        a{
            color: $secendery;
        }
      }
    }
  }
}
