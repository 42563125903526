@mixin redius($value) {
    padding: 15px 20px;
    border: solid 1px  $primary-color;
    border-radius: $value;
    -webkit-border-radius: $value;
    font-size: 15px;
}

@mixin  smoth() {
    transition: all ease-in 0.5s;
    overflow: hidden;
}

@mixin background {
    background-size: auto !important; 
	background-position: center !important;
	background-size: cover !important;
    background-repeat: no-repeat !important;
}





@mixin fouce() {
    border: solid 1px  transparent;
    background: transparent;
    box-shadow: 0 0 0 0.2rem #007bff00;
}