@import "./_variable";
// Banner //
.banner {
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  height: 910px;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 40px;
  .row {
    flex-direction: column;
    align-items: flex-start;
  }
  h6 {
    font-size: 37px;
    margin-bottom: 50px;
    color: $secendery;
  }
  h1 {
    font-size: 48px;
    margin-bottom: 70px;
    color: $primary;
    font-weight: 500;
  }
  form {
    position: relative;
    margin-bottom: 79px;
    .form-group {
      width: 622px;
      height: 96px;
      position: relative;
      background: $slect;
      padding: 15px;
      display: flex;
      align-items: center;
      border: solid 1px $white;
      select {
        width: 84%;
        position: relative;
        background: $slect;
        border-radius: 0;
        border: 0;
        padding: 0 0px 0 82px;
        font-size: 16px;
        color: $secendery;
        outline: transparent;
      }
      button {
        position: absolute;
        right: 15px;
        width: 75px;
        height: 80px;
        color: $secendery;
        border: solid 1px $white;
        svg {
          font-size: 25px;
          color: $white;
        }
      }
    }
  }

  .online_support {
    ul {
      list-style-type: none;
      padding: 0;
      margin-bottom: 40px;
      align-items: center;
      li {
        position: relative;
        &:nth-of-type(1) {
          margin-right: 24px;
          &::before {
            content: "";
            position: absolute;
            border-right: solid 5px #034257;
            height: 100px;
            right: 0;
            top: -14px;
          }
          p {
            white-space: pre-line;
            width: 270px;
            font-size: 16px;
            line-height: 25px;
          }
        }
      }
    }
  }
  .time {
    align-items: flex-start;
    justify-content: flex-start;
    img {
      width: 60px;
      height: auto;
    }
    .colorflex {
      margin-left: 23px;
      align-items: flex-start;
      justify-content: flex-start;
      h5 {
        font-size: 16px;
        margin-bottom: 25px;
        font-family: "Open Sans", sans-serif;
        color: $secendery;
      }
      span {
        font-size: 16px;
        font-family: "Open Sans", sans-serif;
        color: $primary;
      }
    }
  }
}

//project//
.project {
  .background {
    padding: 30px;
    background: $white;
    margin-top: -40px;
    position: relative;
    box-shadow: 5px 11px 9px 0px #746d6d82;
    .headingpart {
      margin-left: 30px;
      h3 {
        font-size: 16px;
        margin-top: 14px;
        color: $secendery;
      }
      span {
        font-size: 16px;
      }
    }
  }
}

//service
.servicebox {
  .service {
    background: $blue2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    .left {
      width: 295px;
      padding-left: 25px;
      h3 {
        color: $primary;
        font-weight: 700;
        margin-bottom: 60px;
      }
      p {
        font-size: 16px;
        line-height: 25px;
        white-space: pre-line;
        color: $primary;
      }
    }
    .imgpart {
      width: 234px;
      margin-left: 20px;
      margin-top: 39px;
      img {
        padding-right: 7px;
      }
    }
  }
}

//about

.about {
  h2 {
    margin-bottom: 52px;
  }
  p {
    margin-bottom: 35px;
  }
  ul {
    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 40px;
      img {
        width: 30px;
        margin-right: 20px;
      }
    }
  }
}

//Instructor

.instructor {
  .expertbox {
    position: relative;
    margin-bottom: 50px;
    .imagepart {
      position: relative;
      transition: all ease-in-out 0.9s;
      &:hover ul.socal-icons {
        transition: all ease-in-out 0.9s;

        opacity: 1;
      }
      .socal-icons {
        position: absolute;
        bottom: 25px;
        z-index: 1;
        right: 10px;
        background: $white;
        width: 48px;
        display: flex;
        flex-direction: column;
        align-items: center;

        opacity: 0;
        li {
          padding: 10px;
          svg {
            color: #fff;
            padding: 6px;
            background: $yellow;
            font-size: 27px;
            border-radius: 50px;
          }
          &:nth-of-type(3) {
            transition: all ease-in-out 0.6s;
            svg {
              background: $red;
            }
          }
          &:nth-of-type(1) {
            transition: all ease-in-out 0.3s;
            animation-delay: 05s;
            svg {
              background: $secendery;
            }
          }
        }
      }
      .icons {
        position: absolute;
        width: 48px;
        height: 48px;
        background: $secendery;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        color: $white;
        cursor: pointer;
        bottom: 13px;
        right: 10px;
        bottom: -23px;
        z-index: 1;
      }
      .imgbox {
        position: relative;
        img {
          position: relative;
        }
      }
    }
    .heading-part {
      margin-top: 30px;
      h5 {
        font-size: 16px;
        margin-bottom: 20px;
      }
      h6 {
        font-size: 14px;
        color: $secendery;
        line-height: 25px;
      }
      span {
        font-size: 14px;
        color: $secendery;
        font-weight: 700;
        margin-top: 25px;
        display: block;
      }
    }
  }
}

//clientSay
.testimonial {
  .clientSay {
    width: 100%;
    padding: 200px 0 50px 0;
    .student {
      align-items: center;
      img {
        width: 70px !important;
        margin-right: 10px;
      }
      .name {
        h5 {
          font-size: 14px;
          color: $primary;
        }
        span {
          font-size: 14px;
          margin-top: 10px;
          color: $secendery;
        }
      }
    }
    .item {
      padding: 80px 60px 60px 60px;
      background: $white;
      position: relative;
      margin-top: 40px;
      margin-bottom: 40px;
      &::before{
        width: 90%;
        height: 40px;
        content: "";
        position: absolute;
        background: $white;
        top: 0;
        left: 0;
        margin-top: -40px;
      }
      &::after{
        width: 90%;
        height: 40px;
        content: "";
        position: absolute;
        background: $white;
        bottom: 0;
        right: 0;
        margin-bottom: -40px;
      }
      h3 {
        line-height: 24px;
        margin-bottom: 50px;
        color: $secendery;
      }
      p {
        margin-bottom: 60px;
      }
      .quite {
        position: absolute;
        bottom: 30px;
        right: 50px;
        img{
          width: auto;
        }
      }
    }
  }
}
