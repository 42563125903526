footer {
  width: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  .footer-top {
    width: 100%;
    padding: 100px 0 50px 0;
    border-bottom: solid 1px $secendery;
    margin-bottom: 70px;
    ul {
      justify-content: flex-end;
      align-items: center;
      li {
        margin-left: 70px;
        a {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          span {
            margin-left: 15px;
            color: $white;
            font-size: 14px;
          }
        }
        &:nth-of-type(1) {
          margin-right: auto;
          margin-left: 0;
          img{
            border: transparent;
            border-radius: 0;
          }
        }
        img {
          width: auto;
          border: solid 1px #0b485d;
          border-radius: 50px;
        }
      }
    }
  }
  .link{
    h3{
        margin-bottom: 70px;
        color: $white;
    }
    ul{
        li{
            a{
                margin-bottom: 30px;
                color: $white;
                padding-left: 0;
                svg{
                    margin-right: 10px;
                }
            }
        }
    }
  }
  .copyright{
    padding: 35px;
    background: $fcolor;
    margin-top: 100px;
    h3{
        text-align: center;
        color: $white;
    }
  }
}
