

header{
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 999;
    .row{
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .bg-light {
        background-color: #f8f9fa00!important;
    }
    .menu{
        justify-content: center;
        .nav-item{
            margin-right: 25px;
            font-size: 23px;
            a{
                color: $primary;
                font-family: 'Joan', serif;
                font-size: 23px;
                
            }
        }
    }

    .navbar-nav{
        a{
            svg{
                transition: all ease-in 0.5s;
                font-size: 39px;
                padding: 10px;
                color:$primary;
                &:hover{
                    background: $secendery;   
                    color: $white;
                }
            }
        }
    }
}